import type { AxiosRequestConfig, AxiosResponse } from 'axios'

import {Modal} from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import store from '@/store'
import { getToken } from '@/utils/auth'
import router from '@/router'

// 添加认证信息
export const addAuth = () => {
  return (config: AxiosRequestConfig) => {
    const Authorization = getToken()
    if (Authorization) {
      config.headers = Object.assign({}, config.headers, {
        Authorization,
      })
    }
    return config
  }
}

// 登录拦截
export let redirectLoginState = false
export function redirectLoginFn(customErrorTips: any) {
  // 防止多次跳转
  if (!redirectLoginState) {
    redirectLoginState = true
    console.log(store.state.user.userInfo.userType)
    console.log(store.state.user.userInfo.userType === 0)
    if(store.state.user.userInfo.userType === 0) { 
      store.dispatch('user/resetToken').then(() => {
        // customErrorTips('请求错误,您的登录信息失效,请重新登录!')
        redirectLoginState = false
        window.open(`${process.env.VUE_APP_LOWCODE_URL}`,'_self')
       
        // setTimeout(()=> {
        //   redirectLoginState = false
          
        // },1000)
       
      })
     
    } else {
      store.dispatch('user/resetToken').then(() => {
        // customErrorTips('请求错误,您的登录信息失效,请重新登录!')
        // setTimeout(()=> {
          redirectLoginState = false
          window.open(`${process.env.BASE_URL}`,'_self')
         
        // },1000)
      
       
      })
     
    }
   
  }
  return true
}

export const redirectLogin = (to: any, customErrorTips: any) => {
  return (res: AxiosResponse) => {
    const {
      data: { status },
    } = res
    if (['52118', '52119', '52120'].includes(String(status))) {
      return redirectLoginFn(customErrorTips)
    }
    return false
  }
}
