





















import { Component, Vue } from 'vue-property-decorator'

@Component
export default class PageView extends Vue {
  get breadcrumbNames() {
    return this.$store.getters['breadcrumb/breadcrumbNames']
    // return process.env.NODE_ENV === 'development'
    //   ? []
    //   : this.$store.getters['breadcrumb/breadcrumbNames']
  }

  get userInfo() {
    return this.$store.state.user.userInfo
  }
  public get getCurrentUrl() {
    if (this.$route.meta) {
      if (this.$route.meta.title.indexOf('年') !== -1) {
        return '年度计划'
      } else if (this.$route.meta.title.indexOf('月') !== -1) {
        return '月度计划'
      } else if (this.$route.meta.title.indexOf('周') !== -1) {
        return '周计划'
      } else if (this.$route.meta.title.indexOf('日') !== -1) {
        return '日计划'
      }
    }
  }
  public get isShow() {
    return this.$route.path!=='/yearPlan/index'
  }
}
