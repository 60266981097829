import Vue from 'vue'
import './lib/antd_components'

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/zh-cn'

import GlobalTitle from '@/components/GlobalTitle'
import TableComponent from '@/components/TableComponent'
import UploadFile from '@/components/UploadFile/index.vue'
import DictSelect from '@/components/DictSelect/index.vue'
import ReadonlyField from '@/components/ReadonlyField/index.vue'
import ProxySelect from '@/components/ProxySelect/index.vue'
import TableColumnDropdown from '@/components/TableColumnDropdown/index.vue'
import PreviewMedia from '@/components/PreviewMedia/index.vue'
import RemoteSelect from '@/components/RemoteSelect/index.vue'
import FormModal from '@/components/FormModal/index.vue'
import MemberInput from '@/components/MemberInput/index.vue'
import AuthMemberInput from '@/components/AuthMemberInput/index.vue'

import { btnAuthMap } from '@/config/route.config'
import dictType from '@/config/dict'
import { log, errorLog } from '@/utils/log'

// 按钮权限
Vue.prototype.$btnAuth = btnAuthMap
// 字典类型
Vue.prototype.$dict = dictType

Vue.prototype.$errorLog = errorLog

Vue.prototype.$log = log

Vue.component('DatePicker',DatePicker)
Vue.component('GlobalTitle', GlobalTitle)
Vue.component('TableComponent', TableComponent)
Vue.component('UploadFile', UploadFile)
Vue.component('DictSelect', DictSelect)
Vue.component('ReadonlyField', ReadonlyField)
Vue.component('ProxySelect', ProxySelect)
Vue.component('TableColumnDropdown', TableColumnDropdown)
Vue.component('RemoteSelect', RemoteSelect)
Vue.component('FormModal', FormModal)
Vue.component('MemberInput', MemberInput)
Vue.component('AuthMemberInput', AuthMemberInput)

export default function Initializer() {
  // store.commit('SET_TOKEN', ls.get('ACCESS_TOKEN'))
}
