


















import { Component, Vue, Prop, ModelSync } from 'vue-property-decorator'
import { getDictionaryList } from '@/api/common'
import DictType from '@/config/dict'

@Component
export default class DictSelect extends Vue {
  @ModelSync('value', 'input', { type: [String, Number, Array] })
  readonly modelValue!: any

  // 字典分类
  @Prop({ type: String, required: true }) dictType!: string

  // 默认下拉数据，会和接口数据合并
  @Prop({ type: Array, default: () => [] }) defaultList!: any[]

  @Prop({ type: Boolean, default: true }) allowClear!: boolean

  @Prop({ type: String, default: '请选择' }) placeholder!: string
  private list: any[] = []

  async getDictionaryListMethod() {
    const [err, res] = await getDictionaryList(DictType[this.dictType] || '')
    if (!err) {
      this.list = [...this.defaultList].concat(
        res.map((item: any) => {
          return { id: item.dataVal, value: item.dataName }
        })
      )
    }
  }

  // 返回当前选中项
  getCurrentItem(currentValue: any) {
    if (!currentValue && currentValue !== 0) {
      return {}
    }
    return this.list.find((item: any) => {
      return item.id === currentValue
    })
  }

  created() {
    this.getDictionaryListMethod()
  }
}
