import BasicLayout from '@/layout/BasicLayout.vue'

export default {
  path: '/weekPlan',
  name: 'weekPlan',
  component: BasicLayout,
  meta: { title: '' },
  redirect: '/weekPlan/index',
  children: [
    {
      path: '/weekPlan/index',
      component: () => import('@/views/week/index.vue'),
      meta: {
        title: '周计划',
      },
    },
    {
      path: '/week/addWeeklyPlan',
      name: 'addWeeklyPlan',
      component: () => import('@/views/week/addWeeklyPlan'),
      meta: {
        title: '周计划',
      },
    },
    {
      path: '/week/editWeeklyPlan/:id',
      name: 'edidWeeklyPlan',
      component: () => import('@/views/week/editWeeklyPlan'),
      meta: {
        title: '周计划',
      },
    },
  ],
}
