const DictType: { [idx: string]: string } = {
  检定状态: 'meter_select_type',
  器具状态: 'meter_status',
  器具用途: 'meter_purpose',
  是否强检:'meter_is_force_verify',
  机构认证强检标识: 'force_verify_sign',
  生产属地类别: 'meter_production_territory',
  能源计量器具使用场合: 'meter_use_scene',
  ABC码: 'meter_abc_code',
  溯源方式: 'tracing_way',
  检定校准周期: 'verify_cycle',
  检校结果确认: 'verification_result',
  巡检结果: 'inspection_report_result',
  故障上报: 'is_breakdown_report',
  保养结果: 'maintain_report_result',
  计划频率: 'plan_period',
  计划状态: 'plan_status',
  故障等级: 'breakdown_level',
  故障确认: 'confirm_result',
  产品类型: 'measurement_result_product_type',
  检测方式: 'measurement_result_detection_mode',
  检测结果: 'measurement_result',
}

export default DictType