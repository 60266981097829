/*
 * @Author: your name
 * @Date: 2021-10-09 09:03:48
 * @LastEditTime: 2021-10-11 08:53:56
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \yibiao163-web-plan\src\utils\index.ts
 */

export function getColors() {
  const colors = [
    '#4680f5',
    '#54b8b4',
    '#c17bfd',
    '#ac5b6d',
    '#fbeb63',
    '#4fc971',
  ]
  return colors
}
//获取时间戳
export function getTimestamp() {
  return new Date().getTime()
}
/**
 * 获取url中的对应参数值
 * @param name
 * @returns {*}
 */
export function getQueryString(name?: string, url?: string) {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  const search = url?.split('?')[1] || ''
  const r = search.match(reg) || []
  return r[2]
}
// 获取localstorage 存值
export function getLocalItem(key: string) {
  return JSON.parse(localStorage.getItem(key) as any) || ''
}
//删除Localstorage
export function removeItem(key: string) {
  localStorage.removeItem(key)
}
//blob 下载
// export  function downLoadBlobFN(result:any,option:any={type:'',name:''}){
//     const {type}=option
//     if (window.navigator && window.navigator.msSaveOrOpenBlob) {
//         window.navigator.msSaveOrOpenBlob(new Blob([result]))
//     } else {
//         const url = window.URL.createObjectURL(new Blob([result], {
//              type:type
//         }))
//         const a = document.createElement('a')
//         a.style.display = 'none'
//         a.href = url
//         if(option.name){
//           a.setAttribute('download', option.name)
//         }
//         document.body.appendChild(a)
//         a.click()
//         document.body.removeChild(a)
//         window.URL.revokeObjectURL(url)
//     }
// }
//通过url下载
export function downLoadURlFN(url: string, name?: string) {
  return new Promise((resolve, reject) => {
    const request = new XMLHttpRequest()
    request.responseType = 'blob' //定义响应类型
    request.open('GET', url)
    request.onload = function () {
      const bloburl = window.URL.createObjectURL(this.response)
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.href = bloburl
      a.download = name as string
      a.click()
      resolve(this.response)
    }
    request.onerror = function (ev: any) {
      reject(ev)
    }
    request.send()
  })
}

export function downloadMultipleFile(
  fileList: any[],
  triggerDelay: number = 1000,
  removeDelay: number = 6000,
) {
  fileList.forEach((item: any, index: number) => {
    setTimeout(() => {
      downLoadURlFN(item.url)
    }, 1000 * index)
    return
    // const frame =
    //   '<iframe style="display: none;" class="multi-download"></iframe>'
    const url = item.url
    const suffix = url.substr(url.lastIndexOf('.') + 1)
    if (suffix.startsWith('pdf')) {
      downLoadURlFN(url)
    } else {
      const frame = document.createElement('iframe')
      frame.style.display = 'none'
      frame.src = url
      //动态添加iframe，设置src，然后删除
      setTimeout(
        function () {
          document.body.appendChild(frame)
          setTimeout(function () {
            frame.remove()
          }, removeDelay)
        },
        index === 0 ? 0 : triggerDelay,
      )
    }
  })
}

//获取时间字符串
export function getCurTimeStr() {
  let timeStr = ''
  const data = new Date()
  const year = data.getFullYear().toString()
  const month =
    data.getMonth() + 1 < 10 ? '0' + (data.getMonth() + 1) : data.getMonth() + 1
  const day = data.getDate() < 10 ? '0' + data.getDate() : data.getDate()
  const hour = data.getHours()
  const minute = data.getMinutes()
  const second = data.getSeconds()
  return (timeStr = year + month + day + hour + minute + second)
}
