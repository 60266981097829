import BasicLayout from '@/layout/BasicLayout.vue'

export default {
  path: '/dailyPlan',
  name: 'dailyPlan',
  component: BasicLayout,
  meta: { title: '' },
  redirect: '/dailyPlan/index',
  children: [
    {
      path: 'index',
      component: () => import('@/views/DailyPlan/index.vue'),
      meta: {
        title: '日计划',
      },
    },
  ],
}
