import { cloneDeep } from 'lodash-es'

export interface BreadcrumbState {
  arr: any[]
}

interface BreadcrumbMutaions {
  SET_ARR(state: BreadcrumbState, arr: any[]): void
}

type AugmentedActionContext = {
  commit<K extends keyof BreadcrumbMutaions>(
    key: K,
    payload?: Parameters<BreadcrumbMutaions[K]>[1],
  ): ReturnType<BreadcrumbMutaions[K]>
  state: BreadcrumbState
}

interface BreadcrumbActions {
  setArr({ commit }: AugmentedActionContext, arr: any[]): void
  setCurrentName({ commit, state }: AugmentedActionContext, name: string): void
}

const getDefaultState = () => {
  return {
    arr: sessionStorage.getItem('breadcrumb')
      ? JSON.parse(sessionStorage.getItem('breadcrumb')!)
      : [],
  } as BreadcrumbState
}

const state = getDefaultState()

const mutations: BreadcrumbMutaions = {
  SET_ARR: (state, arr: any[]) => {
    state.arr = arr
  },
}

const actions: BreadcrumbActions = {
  setArr({ commit }, arr: any[]) {
    commit('SET_ARR', arr)
    sessionStorage.setItem('breadcrumb', JSON.stringify(arr))
  },
  setCurrentName({ commit, state }, name: string) {
    const arr = [...state.arr]
    const lastRoute = arr[arr.length - 1]
    if (lastRoute) {
      const obj = cloneDeep(lastRoute)
      obj.meta.title = name
      arr[arr.length - 1] = obj
    }
    commit('SET_ARR', arr)
    sessionStorage.setItem('breadcrumb', JSON.stringify(arr))
  },
}

const getters = {
  breadcrumbNames: (state: BreadcrumbState) => {
    return state.arr.map(({ name }) => name)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
