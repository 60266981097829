// @ts-nocheck

import type { Method, AxiosInstance, AxiosRequestConfig } from 'axios'
import type { HttpCode } from '@/interface'

import axios from 'axios'
import { message } from 'ant-design-vue'
import {
  redirectLogin,
  addAuth,
  exportExcel,
  network,
  getParamSerialize,
} from './interceptor'
import { redirectLoginFn } from './interceptor/auth'
import { errorLog } from '@/utils/log'
type RejectedInterceptor = (error: any) => any;
export type FulfilledInterceptor = (value: any) => any | Promise<any>;
export interface FetchPlugin {
  (t: typeof to, tips: typeof customErrorTips):
    | FulfilledInterceptor
    | [FulfilledInterceptor, RejectedInterceptor];
}

const REQUEST_INFO: HttpCode = {
  400: {
    message: '您的请求异常，请联系客服处理！',
  },
  401: {
    message: '您的登录信息异常，请联系客服处理！',
  },
  403: {
    message: '您无权访问该资源，请联系客服处理！',
  },
  404: {
    message: '您请求的资源不存在！',
  },
  406: {
    message: '您的请求异常，请联系客服处理！',
  },
  422: {
    message: '您的请求异常，请联系客服处理！',
  },
  others: {
    message: '服务器报错了！',
  },
  777004: {
    message:'无权访问该资源'
  }
}

/**
 *  异步处理 无需try-catch
 * @param {Promise} promise
 */
function to(promise: Promise<any>) {
  return promise.then((res) => [null, res]).catch((err) => [err, null])
}

// 在respCodeWhiteList没有配置错误code时 会默认调用该函数处理错误信息
const customErrorTips = (messageText: string, description: string = '') =>
  message.error(messageText)

/**
 *
 * @param  respSuccCode 响应成功code
 * @param  respErrCodeWhiteList  在此配置code后 不会主动弹出错误提示框 将错误处理权交给页面
 * @param  baseURL 基础url
 * @returns  {instance, get, post, put,delete}
 */
function genMethods(
  respSuccCode: string[],
  respErrCodeWhiteList: string[],
  baseURL: string
) {
  let fetchUrl = baseURL
  if (process.env.NODE_ENV === 'development') {
    fetchUrl = localStorage.getItem('fetchUrl') || baseURL
  }
  const instance: AxiosInstance & { [index: string]: any } = axios.create({
    baseURL: fetchUrl,
    timeout: 30 * 60 * 1000, // request timeout  导出时长过长因此设置半小时
  })

  // 断网提示
  instance.interceptors.request.use(
    network(to, customErrorTips) as FulfilledInterceptor
  )

  // ie get 缓存问题
  // instance.interceptors.request.use(ieGetCache())

  // 添加请求拦截器
  instance.interceptors.request.use(addAuth())

  // get参数序列化
  instance.interceptors.request.use(getParamSerialize())

  // 添加响应拦截器
  instance.interceptors.response.use(
    async (res) => {
      const {
        status,
        data,
        config: { url, responseType },
      } = res
      // 网络错误
      if (status !== 200) {
        customErrorTips(`${status} 网络错误`)
        return to(
          Promise.reject(new Error(`${url} 请求错误 res.status = ${status}`))
        )
      }
      //登录过期
      if(data.status === 777004) {
        redirectLoginFn(customErrorTips)
        return
      }
      // //低代码退出登录，跳转登录界面
      // if(data.status === 777004) {
      //   redirectLoginFn(customErrorTips)
      //   return
      // }
      // 请求类型为blob
      if (responseType === 'blob') return res
      // 业务请求异常
      if (!respSuccCode.includes(String(data.status))) {
        // 登录拦截
        if ((redirectLogin(to, customErrorTips) as FulfilledInterceptor)(res)) {
          return to(Promise.reject(res))
        }
        // 错误status白名单不弹出错误信息只输出错误信息
        if (respErrCodeWhiteList.includes(String(data.status))) {
          errorLog(
            `request error url = ${url} data = ${JSON.stringify(
              data
            )}} respCodeWhiteList call`
          )
          return to(Promise.reject(res))
        }
        customErrorTips(data.msg || data.message)
        errorLog(`request error url = ${url} data = ${JSON.stringify(data)}}`)
        return to(Promise.reject(res))
      }
      return to(Promise.resolve(data.data))
    },
    (error) => {
      if (error.response) {
        const status = error.response.status
        if (status === 401) {
          // 无token或token过期处理
          redirectLoginFn(customErrorTips)
        } else if (String(status).indexOf('4') > -1) {
          customErrorTips('请求错误', REQUEST_INFO[status].message)
        } else {
          customErrorTips('请求错误', REQUEST_INFO.others.message)
        }
      }
      errorLog(`request error `, error)
      return to(Promise.reject(error))
    }
  )

  // 添加导出拦截器 一定要在响应拦截器后
  instance.interceptors.response.use(
    ...(exportExcel(to, customErrorTips) as [
      FulfilledInterceptor,
      RejectedInterceptor
    ])
  )

  const withoutDataMethod = (
    method: Method,
    url: string,
    params: any,
    config: AxiosRequestConfig = {}
  ) =>
    instance[method.toLowerCase()](url, {
      params,
      ...config,
    })

  const withDataMethod = (
    method: Method,
    url: string,
    data: any,
    config: AxiosRequestConfig = {}
  ) => instance[method.toLowerCase()](url, data, config)

  const result = {
    instance,
    GET: (url: string, param?: any, config: AxiosRequestConfig = {}) =>
      withoutDataMethod('GET', url, param, config),
    DELETE: (url: string, param?: any, config: AxiosRequestConfig = {}) =>
      withoutDataMethod('DELETE', url, param, config),
    PUT: (url: string, data?: any, config: AxiosRequestConfig = {}) =>
      withDataMethod('PUT', url, data, config),
    POST: (url: string, data?: any, config: AxiosRequestConfig = {}) =>
      withDataMethod('POST', url, data, config),
  }
  return result
}

const { GET, POST, DELETE, PUT } = genMethods(
  ['0'],
  [
    '52137', // 验证手机号、邮箱、用户名接口 "该用户已注册"
    '74020', // 器具批量导入,全部导入并赋码成功
    '74021', // 器具批量导入,全部导入成功赋码失败
    '74022', // 器具批量导入, 部分导入成功
    '74023', // 器具批量导入，全部失败
  ],
  process.env.VUE_APP_API_BASE_URL
)

export { GET, POST, DELETE, PUT }
