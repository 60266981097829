







import { Component, Vue } from 'vue-property-decorator'
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
import 'moment/locale/zh-cn'
@Component({
  components: {},
  data() {
    return {
      locale: zh_CN
    }
  }
})
export default class App extends Vue {
  locale: any
}
