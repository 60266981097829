



























import { namespace } from 'vuex-class'
import { Component, Vue, Watch } from 'vue-property-decorator'
import SubMenu from './subMenu.vue'

const commonModule = namespace('permission')

@Component({
  components: {
    SubMenu,
  },
})
export default class GlobalMenu extends Vue {
  @commonModule.Getter('permission_routes') permissionRoutes: any
  private openKeys: any[] = []
  private selectedKeys: any[] = []

  get menuList() {
    const routes = this.permissionRoutes
      .filter((item: any) => !!item.meta)
      .map((item: any) => {
        if (!item.children) return item
        const onlyOneChild =
          (item.children as Array<any>).filter(
            (_item: any) => !_item.meta.hidden
          ).length === 1 && item.meta.skipAuth
        if (onlyOneChild) {
          const child = (item.children as Array<any>)[0]
          return {
            ...child,
            path: this.resolvePath(item.path + '/' + child.path),
          }
        }
        return item
      })
    // 平台板的超级管理员不可见首页
    const list =
      this.$store.getters['user/isSuperAdmin'] 
        ? []
        : [
            {
              path: '/home/index',
              meta: {
                title: '首页',
              },
              name: 'home',
              children: false,
            },
          ]
    const result = list.concat(routes)
    return result
  }

  private onOpenChange(Keys: any[]) {
    // 当菜单被展开时触发此处
    if (this.openKeys.length !== 0) {
      this.openKeys = []
    } else {
      this.openKeys = [Keys[0]]
    }
  }

  private clickItem(obj: any) {
    if (!obj.children) {
      this.$router.push(obj.path)
    }
  }

  private handleClick(event: any) {
    this.$router.push(this.resolvePath(event.key))
  }

  private resolvePath(path: string) {
    return path.replace(/(\/){2,}/g, '/')
  }

  // 找到左侧按钮对应的路由path
  findMenuRoute(menuList: any[]) {
    // 找到第一个隐藏的路由，第一个隐藏路由的前一项就是左侧需要选中的菜单
    let index = menuList.findIndex(
      (item: any) => item.meta && item.meta.hidden
    )
    index = index === -1 ? menuList.length - 1 : index - 1
    return menuList[Math.max(0, index)]
  }

  initDefaultOpenKeys() {
    const arr = this.$store.state.breadcrumb.arr
    this.openKeys = [...new Set([...this.openKeys, arr[0].name])]
    const path = this.findMenuRoute(arr).path
    this.selectedKeys = [path]
  }

  //  浏览器前进后退 同步菜单状态
  @Watch('$route')
  handleRouteChange() {
    const currentRoute = this.$route
    // 当前路由与选中的菜单不一致，重新计算菜单的选中和打开状态
    if (!this.selectedKeys.includes(currentRoute.path)) {
      // 已经打开的菜单包含当前菜单，则不用重新计算打开的状态
      this.initDefaultOpenKeys()
    }
  }
  @Watch('openKeys')
  handleOpenKeysChange(newKeys: string[]) {
    if (newKeys.length > 1) {
      this.openKeys = [newKeys[newKeys.length - 1]]
    }
  }

  mounted() {
    this.initDefaultOpenKeys()
  }
}
