/**
 * v-action使用:
 */
import Vue from 'vue'
import { AUTH_SWITCH } from '@/config/route.config'

const down = Vue.directive('down', {
  inserted: (el, binding, vnode) => {
    el.style.cssText = 'cursor: pointer;color:write;'
    el.addEventListener('click', () => {
      const link = document.createElement('a')
      const url = (vnode.context as any).dataMap?.url
      // 这里是将url转成blob地址，
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          // 将链接地址字符内容转变成blob地址
          link.href = URL.createObjectURL(blob)
          link.download = ''
          document.body.appendChild(link)
          link.click()
        })
    })
  },
})

const hasPermission = (
  permissionList: string[],
  btnAuth: string | string[]
) => {
  // 同一个按钮可能存在于两个页面，按钮可以传递两个页面的权限
  const authList = Array.isArray(btnAuth) ? btnAuth : [btnAuth]
  const result = permissionList.some((permission: string) =>
    authList.includes(permission)
  )
  return result
}

const filterPermission = (el: any, binding: any, vnode: any) => {
  const permissionList = []
  const route=vnode.context.$route
  // 匹配父级及当前路由的权限
  for (const record of route.matched) {
    if(record.meta&&record.meta.permissions){
      permissionList.push(...record.meta.permissions)
    }
  }
  if (!permissionList.length) {
    (el.parentNode && el.parentNode.removeChild(el)) ||
      (el.style.display = 'none')
    return
  }
  if (!hasPermission(permissionList, binding.value)) {
    (el.parentNode && el.parentNode.removeChild(el)) ||
      (el.style.display = 'none')
  }
}

const action = Vue.directive('action', {
  inserted: (el, binding, vnode) => {
    if (AUTH_SWITCH) {
      filterPermission(el, binding, vnode)
    }
  },
})

export { action, down }
