export const log = (message: string, ...args: any[]) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    // console.log(message, ...args)
  }
}

export const errorLog = (message: string, ...args: any[]) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error(message, ...args)
  }
}
