import Vue from 'vue'
import VueRouter, { RawLocation, Route, RouteConfig } from 'vue-router'
import BasicLayout from '@/layout/BasicLayout.vue'
import DailyPlanRoute from './modules/dailyPlan'
import WeekPlanRoute from './modules/weekPlan'
import YearPlanRoute from './modules/yearPlan'
import MonthPlanRoute from './modules/monthPlan'
import ReportRoute from './modules/report'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push as unknown as Promise<Route>
VueRouter.prototype.push = function push(location: RawLocation) {
  return (originalPush as any).call(this, location).catch((err: Error) => err)
}

export const constantRoutes: Array<RouteConfig> = [
  {
    path: '/login',
    component: () => import('@/views/login/index'),
  },
  {
    path: '/download',
    component: () => import('@/views/download/index'),
  },
  {
    path: '/detail',
    component: () => import('@/views/detail/index'), 
  },
  {
    path: '/privacy',
    component: () => import('@/views/privacy/index'),
  },
  {
    path: '/userProtocol',
    component: () => import('@/views/userProtocol/index'),
  },
  {
    path: '/childrenProtocol',
    component: () => import('@/views/childrenProtocol/index'),
  },
  DailyPlanRoute,
  WeekPlanRoute,
  YearPlanRoute,
  MonthPlanRoute,
  ReportRoute,
  {
    path: '/',
    name: 'main',
    redirect: '/dailyPlan',
  },
  {
    path: '/404',
    component: () => import('@/views/404.vue'),
  },
]

export const dynamicRoute = [
  // systemSetting,
  { path: '*', redirect: '/404' },
]

const createRouter = () =>
  new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: constantRoutes,
  })

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  // @ts-ignore
  router.matcher = newRouter.matcher
}

export default router
