<template>
  <div>
    <a-modal
      width="666px"
      :footer="null"
      title="可访问统计模块的角色配置"
      @cancel="handleCancel"
      :visible="personVisible"
    >
      <div class="flex">
        <a-card style="width: 50%; border: none">
          <div class="treeNode">
            <a-input-search
              v-model="searchText"
              style="margin-bottom: 8px"
              placeholder="输入角色名称"
            />
            <a-tree
              key="id"
              checkable
              @check="onCheck"
              @expand="onExpand"
              :checkStrictly="false"
              :defaultExpandAll="true"
              :treeData="filteredData"
              :checkedKeys="checkedKeys"
              :expandedKeys.sync="expandedKeys"
              :autoExpandParent="autoExpandParent"
            >
              <template slot="title" slot-scope="text">
                <a-icon
                  type="user"
                  v-if="text.type === 'user'"
                  style="margin-right: 5px"
                />

                <span v-if="text && text.title.indexOf(searchText) > -1">
                  {{ text.title.substr(0, text.title.indexOf(searchText)) }}
                  <span style="color: #ff0000">{{ searchText }}</span>
                  {{
                    text.title.substr(
                      text.title.indexOf(searchText) + searchText.length,
                    )
                  }}
                </span>
                <span v-else>{{ text.title }}</span>
              </template>
            </a-tree>
          </div>
        </a-card>
        <a-card
          style="width: 50%"
          :title="`已选：${searchData.length}`"
          size="small"
        >
          <a slot="extra" @click="clearAll">清空</a>
          <div class="selected_list treeNode">
            <div
              class="selected_item"
              v-for="(item, index) in searchData"
              :key="index"
            >
              {{ item.dataRef.title }}
              <a-button
                icon="close"
                size="small"
                class="selected_del"
                @click="clearItem(item.dataRef.id, index, item)"
              />
            </div>
          </div>
        </a-card>
      </div>
      <div class="group-footer">
        <a-button
          style="margin-right: 10px"
          @click="handleCancel"
        >取消</a-button
        >
        <a-button type="primary" @click="handleOver">确定</a-button>
      </div>
    </a-modal>
  </div>
</template>
 
<script>
import {
  getPositionPermissionList,
  getPositionPermissionListChosed,
  setpsiotion,
} from '@/api/statistics'
export default {
  components: {},
  props: {
    // // 树状图原始数据
    // personList: {
    //   type: Array,
    //   default: () => [],
    // },
    // 树状图选中数据
    personCheckList: {
      type: Array,
      default: () => [],
    },
    // 弹窗显示
    personVisible: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    personVisible: {
      handler() {
        this.initData()
      },
    },
  },
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      // 树状图数据
      treeData: [],
      // 加载状态
      loading: false,
      // 输入框
      searchText: '',
      // 选中数据
      searchData: [],
      checkedKeys: [],
      expandedKeys: [],
      // 默认选中数据
      // defaultCheckList: [],
      confirmLoading: false,
      // 父节点自动展开
      autoExpandParent: true,
    }
  },
  computed: {
    // 根据搜索关键字过滤数据
    filteredData() {
      return this.searchText
        ? this.filterData(this.treeData, this.searchText)
        : this.treeData
    },
  },
  mounted() {
    // alert(1)
    this.getSysOrgByToken()
    this.getHasChosed()
  },
  created() {},
  methods: {
   flattenTreeData(treeData) {
    const flatData = []
    function flatten(node) {
        flatData.push(node)
        if (node.children && node.children.length > 0) {
            node.children.forEach(child => {
                flatten(child)
            })
        }
    }

    treeData.forEach(node => {
        flatten(node)
    })

    return flatData
},
    async getHasChosed() {
      const [err,data] = await getPositionPermissionListChosed()
      if(!err) {
        // debugger
      data.forEach((v)=> {
         v.id = v.positionId
          v.dataRef = {
            title:v.positionName,
            id:v.positionId
          }
        })
        this.searchData = data
        this.checkedKeys = this.searchData.map((el) => {
          return el.dataRef.id
        })
        console.log(this.searchData)
      }
    },
    async getSysOrgByToken() {
      const [err, data] = await getPositionPermissionList()
      if (!err) {
        // console.log(data)
         var newData =this.flattenTreeData(data)
        this.treeData = this.processData(newData)
        // this.initData()
      }
    },
    // 初始化数据
    // initData() {
    //   // 将默认选中数据中的key值存储checkedKeys数组中
    //   if (this.personCheckList.length > 0) {
    //     // debugger
    //     this.searchData = this.personCheckList
    //     this.checkedKeys = this.personCheckList.map((item) => item.dataRef.key)
    //   }
    //   // this.treeData = this.processData(this.personList)
    //   // console.log(this.treeData)
    // },
    onClickParticipatePerson(e) {
      this.personVisible = true
    },
    // 删除当前选中审批人员数据
    clearParticipatePersonItem(id, index, record) {
      this.personCheckList.splice(index, 1)
    },
    // 树状图数据处理
    processData(data) {
      const result = []

      data.forEach((item) => {
        // 生成树状数据节点
        const nodeItem = {
          key: item.id,
          id: item.id,
          children: [],
          title: item.positionName,
        }

        result.push(nodeItem)
      })
      return result
    },
    // 人员数据处理
    processPersonList(personList) {
      return personList.map((item) => ({
        key: item.id,
        title: item.positionName,
      }))
    },
    // 输入框搜索
    filterData(data, searchText) {
      const result = []
      for (const item of data) {
        if (item.title.includes(searchText)) {
          result.push(item)
          continue
        }
        if (item.children) {
          const children = this.filterData(item.children, searchText)
          if (children.length > 0) {
            this.expandedKeys.push(item.key)
            result.push({ ...item, children })
          }
        }
      }

      if (this.searchData.length > 0) {
        this.checkedKeys = this.searchData.map((res) => {
          return res.id
        })
      }
      // console.log(result)
      return result
    },
    // 人员选择完成
    async handleOver() {
      this.confirmLoading = true

      const postData = []
      this.searchData.forEach((item) => {
        postData.push({
          positionId: item.dataRef.id,
          positionName: item.dataRef.title,
        })
      })
      const [err, data] = await setpsiotion(postData)
      if (!err) {
        this.$message.success('设置成功')
      }
      // 将选中数据传递向父组件
      this.$emit('personCheckUpdate', this.searchData)

      // 关闭弹窗
      this.$emit('personCancel', false)
    },
    // 关闭人员选择弹窗
    handleCancel() {
      // 清空选中数据
      this.searchText = ''
      this.searchData = []
      this.checkedKeys = []
      // 关闭弹窗
      this.$emit('personCancel', false)
    },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    // 树状图节点点击
    onCheck(checkedKeys, nodes) {
      this.checkedKeys = checkedKeys
      // debugger
      console.log(checkedKeys, nodes)
      if (nodes.checked === false) {
        if (checkedKeys.length === 0) {
          this.searchData = []
          return false
        }
        this.searchData = this.searchData.filter((obj) =>
          checkedKeys.includes(obj.dataRef.id),
        )
      } else {
        //  console.log(nodes.checkedNodes)
        nodes.checkedNodes.forEach((item) => {
          this.searchData.push(item.data.props)
          this.searchData = this.searchData.filter(
            (items, index, self) =>
              index === self.findIndex((t) => t.id === items.id),
          )
        })
      }

      this.$forceUpdate()
      // console.log(this.searchData)
    },
    // 清除所有选中节点
    clearAll() {
      this.searchData = []
      this.checkedKeys = []
    },
    // 清除当前选中节点
    clearItem(id, index, record) {
      // debugger
      this.searchData.splice(index, 1)
      const index2 = this.checkedKeys.indexOf(id)
      if (index !== -1) {
        this.checkedKeys.splice(index2, 1)
      }
      this.checkedKeys = this.searchData.map((item) => {
        return item.dataRef.id
      })
    },
  },
}
</script>
 
<style lang="less" scoped>
.treeNode {
  height: 400px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 6px;
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: rgba(144, 147, 153, 0.5);
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background: transparent;
  }
}
.flex {
  display: flex;
}
/deep/ .ant-card-body {
  padding: 0;
}
/deep/ .ant-card-head {
  background-color: #fff;
}
.selected_list {
  flex: 1 1;
  margin: 0 4px;
  overflow: auto;
  .selected_item {
    height: 36px;
    display: flex;
    padding: 0 16px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 2px;
    color: #111a34;
    position: relative;
    align-items: center;
    justify-content: space-between;
    &:hover {
      background-color: #f7f8fb;
      .selected_del {
        display: block;
      }
    }
    .selected_del {
      display: none;
      cursor: pointer;
      transition: all 0.3s;
    }
  }
}

.group-footer {
  display: flex;
  margin-top: 30px;
  justify-content: center;
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #0176f6;
  border-color: #0176f6;
}
</style>