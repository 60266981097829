






















import { Component, Vue, Prop, Model, Watch } from 'vue-property-decorator'

import { getDictionaryList } from '@/api/common'
import DictType from '@/config/dict'

@Component
export default class TableColumnDropdown extends Vue {
  @Model('input', { type: [String, Number], default: undefined })
  readonly modelValue!: any

  @Watch('modelValue')
  handleModelChange(nval: string | number) {
    if (!nval && nval !== 0) {
      this.displayName = this.dictType
    } else {
      this.displayName = this.getDisplayName(nval)
    }
  }

  // 字典分类
  @Prop({ type: String, required: true }) dictType!: string

  // 默认下拉数据，会和接口数据合并
  @Prop({ type: Array, default: () => [] }) defaultList!: any[]

  private displayName = ''
  private statelist: any[] = []

  async fetchList() {
    const [err, res] = await getDictionaryList(DictType[this.dictType] || '')
    if (!err) {
      this.statelist = [
        {
          id: undefined,
          value: '全部',
        },
        ...this.defaultList,
      ].concat(
        res.map((item: any) => {
          return { id: item.dataVal, value: item.dataName }
        })
      )
      // 没有默认值则选中第一项
      if (!this.modelValue) {
        this.handleClick(this.statelist[0])
      } else {
        this.displayName =
          this.statelist.find((item: any) => item.id === this.modelValue)
            ?.value ?? ''
      }
    }
  }

  handleClick(item: any) {
    // if (item.id !== this.modelValue) {
    this.displayName = item.value
    this.$emit('input', item.id)
    // }
  }
  getDisplayName(val: string | number) {
    const retult = this.statelist.find((item: any) => item.id === val) ?? {
      id: '',
      value: this.dictType,
    }
    return retult.value
  }

  created() {
    this.fetchList()
  }
}
