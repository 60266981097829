import BasicLayout from '@/layout/BasicLayout.vue'

export default {
  path: '/yearPlan',
  name: 'yearPlan',
  component: BasicLayout,
  meta: { title: '' },
  redirect: '/yearPlan/index',
  children: [
    {
      path: 'index',
      component: () => import('@/views/year/index.vue'),
      meta: {
        title: '年计划',
      },
    },
    {
      path: '/year/addYearPlan',
      name: 'addYearPlan',
      component: () => import('@/views/year/addYearPlan/index.vue'),
      meta: {
        title: '年计划',
      },
    },
    {
      path: '/year/showYearPlan',
      name: 'showYearPlan',
      component: () => import('@/views/year/showYearPlan/index'),
      meta: {
        title: '年计划',
      },
    },
  ],
}