import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context('./modules', true, /\.ts$/)

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
// eslint-disable-next-line no-shadow
const modules = modulesFiles.keys().reduce((modules: any, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

import type { UserState } from './modules/user'
import type { BreadcrumbState } from './modules/breadcrumb'

interface State {
  user: UserState
  breadcrumb: BreadcrumbState
}
const store = new Vuex.Store<State>({
  modules,
})

export default store
