import { GET, POST, DELETE, PUT } from '@/config/fetch'


// 账号密码登录
export const login = (params: any) => POST('/dailylog/token/login/password', params)
// 手机验证码登录
export const loginCode = (params: any) => POST('/dailylog/token/login/sms', params)
// 单点登录
export const singleSign = (token: string, apiToken: string,custId:string) =>
  GET(`/dailylog/token/login/singleLogin`,{token,apiToken,custId})
// 发送手机号
export const postSms = (params: any) => POST(`/dailylog/token/login/sms/${params}`)
// 退出
export const logout = () => POST('/dailylog/token/logout')
// 用户信息
export const getUserInfo = () => GET('/dailylog/token/user')
//留言点赞
export const commentRecords = (params: any) => POST('/front/dailylog/v1/commentRecords',params)
//删除留言点赞
export const deleteCommentRecords = (id:string,) => DELETE(`/front/dailylog/v1/commentRecords/${id}`)
//修改评论
export const editComment = (id:string,params: any) => PUT(`/front/dailylog/v1/commentRecords/${id}`,params)
//获取当前用户下的组织树
export const getUserOrgTree = (type:number) => GET(`/front/dailylog/v1/org/orgUser/tree?type=0`)
//获取当前用户下的组织树下的用户
export const getUserListTeamPage = (params:string) => GET(`/front/dailylog/v1/org/user/tree?type=0&orgId=${params}`)
//获取当前用户最新一次的分享人
export const getShareLast = (type:number) => GET(`/front/dailylog/v1/share/last?planType=${type}`)
//查询点赞列表
export const commentTypeRecords = (id:string) => GET(`/front/dailylog/v1/commentRecords/praiseList/${id}`)
//查询评论列表
export const commentRecordsList = (id:string) => GET(`/front/dailylog/v1/commentRecords/list/${id}`)
//升级检测android端
export const upgradeAndroid = () => GET(`/nai/dailylog/v1/appUpgrade/upgradeAndroid`)
// 用户权限信息
export const getUserAuthInfo = () => GET('/sysUser/auth')

// 文件上传
export const uploadFile = (params: any, options?: any) =>
  POST(
    `/front/dailylog/v1/attachment/upload`,
    params,
    options,
  )

// 删除前验证是否有权限删除
export const checkDeleteFile = (fk: string) =>
  GET(`/sysAttachment/checkFile/${fk}`)

// 文件删除
export const deleteFile = (id: string) => DELETE(`/sysAttachment/${id}`)

// 获取管辖区列表
export const getAreaCodeList = () => GET('/meterAreaCode')

// 更新所在管辖区
export const updateAreaCode = (code: string) => PUT(`/custInfo/area/${code}`)

// 获取登录用户下属机构(树形)
export const organizationList = () => GET(`/sysOrganization/organizationTree`)

// 获取组织成员
export const orgMemberList = (params?: any) => GET('/sysUser/list', params)

//字典项列表
export const getDictionaryList = (typeName: string) =>
  GET(`/dictionary/${typeName}`)

// 查询器具分类
export const getCategoryList = (params: any) =>
  GET(`/meterCatalogue/bigCatalogue`, params)

// 查询标准名称
export const getCategoryCriterion = (params: any) =>
  GET(`/meterCatalogue/categoryCriterion`, params)

// 查询制造厂商
export const getFactory = (params?: any) => GET('/meter/factory', params)
