import router from './router'
import { getToken } from '@/utils/auth'
import store from '@/store'
import { errorLog } from '@/utils/log'
import antd from 'ant-design-vue'
import Vue from 'vue'
const whiteList = [
  '/login',
  '/mobileDetail',
  '/download',
  '/privacy',
  '/userProtocol',
  '/childrenProtocol',
] // no redirect whitelist
//设置一个10分钟获取用户信息的定时器
let timer: any

const clearTimer = (time: any) => {
  clearInterval(time)
  timer = null
}
async function redirectLogin(next: any) {
  clearTimer(timer)
  await store.dispatch('user/resetToken')
  next(`/login`)
}

// 登录拦截
router.beforeEach(async (to, from, next) => {
  //清除上一个定时器
  clearInterval(timer)
  //设置每10分钟获取用户信息定时器
  timer = setInterval(() => {
    store.dispatch('user/getInfo')
  }, 600000)
  // 白名单放行
  if (whiteList.includes(to.path)) {
    clearTimer(timer)
    next()
    return
  }
  // determine whether the user has logged in
  const hasToken = getToken()
  if (to.query.token) {
    // url上带有token代表单点登录过来的
    const { token, apiToken,custId } = to.query
    console.log(token, apiToken,custId)
    store
      .dispatch('user/singleSign', { token, apiToken, custId })
      .then(async () => {
        if (to.query.planId) {
          const hasUserInfo = !!store.state.user.name
          if (!hasUserInfo) {
            await store
              .dispatch('user/getInfo')
              .then(next)
              .catch((e) => {
                next()
              })
          } else {
            next()
          }
          // next()
        } else {
          if(apiToken) {
            next(to.path)
          } else {
            window.open(`${process.env.BASE_URL}`,'_self')
          }
          
        }
      })
      .catch(() => {
        clearTimer(timer)
        // console.log('permission getUserInfo error', e)
        next('/login')
      })
    return
  } else if (hasToken) {
    // 登录状态下访问登录页面 跳转到首页
    if (to.path === '/login') {
      clearTimer(timer)
      next({ path: '/' })
      return
    }
  } else {
    redirectLogin(next)
    return
  }
  //刷新页面调用接口
  if (from.path === '/') {
    store.dispatch('user/getInfo')
  }
  // 获取用户信息
  const hasUserInfo = !!store.state.user.name
  if (!hasUserInfo) {
    await store
      .dispatch('user/getInfo')
      .then(next)
      .catch((e) => {
        next()
      })
  } else {
    next()
  }
})

// 权限拦截
// router.beforeEach(async (to, from, next) => {
//   // 白名单放行
//   if (whiteList.includes(to.path)) {
//     next()
//     return
//   }
//   const hasPermissionRoutes =
//     store.getters['permission/permission_routes'] &&
//     store.getters['permission/permission_routes'].length > 0
//   if (hasPermissionRoutes) {
//     next()
//   } else {
//     try {
//       const accessRoutes = await store.dispatch('permission/generateRoutes')
//       router.addRoutes(accessRoutes)
//       // @ts-ignore
//       next({ ...to, replace: true })
//     } catch (error) {
//       errorLog('permissionRoute error', error)
//       await store.dispatch('user/resetToken')
//       next(`/login`)
//     }
//   }
// })

// 生成面包屑
// router.beforeEach((to, from, next) => {
//   let newArr = []
//   // 切换新的菜单，重新记录面包屑
//   if (!to.meta?.hidden) {
//     newArr = to.matched.map(({ name, meta, path }) => ({ name, meta, path }))
//   } else {
//     newArr = [...(store as any).state.breadcrumb.arr] as any[]
//     const arrLen = newArr.length
//     const index = newArr.findIndex(({ name }) => name === to.name)
//     const lastRoute = newArr[arrLen - 1]
//     // 跳转的路径在当前面包屑中(且不是点击的最后一项)
//     if (index > -1 && index < arrLen - 1) {
//       newArr = newArr.slice(0, index + 1)
//     } else if (lastRoute && lastRoute.name !== to.name) {
//       // 最后一项与当前路由不一致, 添加到面包屑中
//       const { name, meta, path, params } = to
//       newArr.push({
//         name,
//         meta,
//         path,
//         params,
//       })
//     } else if (lastRoute && lastRoute.name === to.name) {
//       // 最后一项与当前路由一致，params不一致。使用缓存的params，避免刷新丢失params
//       if (
//         Object.keys(lastRoute.params).length > 0 &&
//         Object.keys(to.params).length < 1
//       ) {
//         // @ts-ignore
//         return next({ ...to, params: { ...lastRoute.params } })
//       }
//     }
//   }
//   store.dispatch('breadcrumb/setArr', newArr)
//   next()
// })
