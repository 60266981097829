































import { Component, Vue, Prop, ModelSync } from 'vue-property-decorator'
import { debounce } from 'lodash-es'

@Component
export default class RemoteSelect extends Vue {
  @ModelSync('value', 'change', { type: [String, Number, Object] })
  readonly modelValue!: any

  @Prop({ type: Boolean, default: true }) allowClear!: boolean

  @Prop({ type: Function, required: true }) remoteMethod: any

  @Prop({ type: String, default: '' }) defaultSearchValue!: string
  @Prop({ type: String, default: '请选择' }) placeholder!: string

  private list: any[] = []

  private lastFetchId = 0

  private fetching = false

  created() {
    // @ts-ignore
    this.handleSearch = debounce(this.handleSearch, 800)
    // 如果有默认搜索内容，则触发搜索(用于回显数据)
    // if (this.defaultSearchValue) {
    this.handleSearch(this.defaultSearchValue)
    // }
  }

  async handleSearch(searchValue: string) {
    if (!searchValue) return
    this.lastFetchId += 1
    const fetchId = this.lastFetchId
    this.list = []
    this.fetching = true
    const list = await this.remoteMethod(searchValue)
    if (fetchId !== this.lastFetchId) return
    this.list = list
    this.fetching = false
  }
}
