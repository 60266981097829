// import ls from '@/config/localstorage'
import { getUserAuthInfo } from '@/api/common'
import { constantRoutes, dynamicRoute } from '@/router'
import BasicLayout from '@/layout/BasicLayout.vue'
const mainRoute = {
  path: '/',
  name: 'main',
  redirect: '/home/index',
  component: BasicLayout,
  children: [
    {
      path: '/home/index',
      name: 'home',
      component: () => import('@/views/home'),
      meta: {
        title: '首页',
      },
    },
  ],
}

const blankRoute = {
  path: '/',
  name: 'main',
  redirect: '',
}
const routeKeyConfig = {
  childrenKey: 'children',
  titleKey: 'title',
  urlKey: 'path',
  compKey: 'component',
  actionKey: 'permissions',
}

const getCompNameMap = (item: any, res: any, btnAuthList: any[]) => {
  if (item[routeKeyConfig.compKey]) {
    res[item[routeKeyConfig.compKey]] = item
    if (item[routeKeyConfig.actionKey]) {
      btnAuthList.push(...item[routeKeyConfig.actionKey])
    }
  }
  if (
    item[routeKeyConfig.childrenKey] &&
    item[routeKeyConfig.childrenKey].length > 0
  ) {
    for (const _item of item[routeKeyConfig.childrenKey]) {
      getCompNameMap(_item, res, btnAuthList)
    }
  }
}

// userRouterMap 生成组件name到接口菜单的映射
// btnAuthList 所有按钮权限集合
export const genUserRouteMap = (navigation: any[]) => {
  if (!navigation || navigation.length < 1) return {}
  const userRouterMap = {}
  const btnAuthList: any[] = []
  for (const item of navigation) {
    getCompNameMap(item, userRouterMap, btnAuthList)
  }
  return {
    userRouterMap,
    btnAuthList,
  }
}

// 判断是否具有权限
function hasPermission(userRouteMap: any, route: any, _: any) {
  // map中存在，代表有该理由权限 前端路由name去匹配后端路由配置中的component字段
  const has = userRouteMap[route.name]
  if (has) return !!has
}

// 判断是否有按钮权限对应的路由
function hasBtnPermission(_: any, route: any, btnAuthList: any) {
  // 判断当前路由是否是权限按钮对应的路由
  if (!route.meta || (route.meta && !route.meta.btnAuth)) return false
  const hasBtnRoute = btnAuthList.some((auth: string) =>
    route.meta.btnAuth.includes(auth)
  )
  return hasBtnRoute
}

// 判断当前路由是否需要跳过权限控制
function hasSkipPermission(_: any, route: any, __: any) {
  if (!route.meta || (route.meta && !route.meta.skipAuth)) return false
  return true
}

// 根据服务端路由设置路由信息（title，path）
function initRouteInfo(userRouteMap: any, route: any) {
  const serverRoute = userRouteMap[route.name]
  if (!serverRoute) return
  const title = serverRoute[routeKeyConfig.titleKey]
  const path = serverRoute[routeKeyConfig.urlKey] || '/'
  const permissions = serverRoute[routeKeyConfig.actionKey]
  const sort = serverRoute.sort
  if (route.meta) {
    route.meta.title = title
    route.meta.sort = sort
    route.meta.permissions = permissions
  }
  route.originPath = route.path
  route.path = path
}

function sortRoutes(routes: any) {
  return routes.sort((a: any, b: any) => {
    const aValue = (a.meta && a.meta.sort) || Number.MAX_VALUE
    const bValue = (b.meta && b.meta.sort) || Number.MAX_VALUE
    return aValue - bValue
  })
}

/**
 * 过滤出有权限的菜单
 * @param asyncRoutes 需要判断权限的路由
 * @param userRouteMap  组件名称=>接口路由配置的映射对象
 */
export function filterAsyncRoutes(
  routes: any[],
  userRouteMap: any,
  btnAuthList: any[]
) {
  const res: any[] = []
  routes.forEach((route) => {
    const tmp = { ...route }
    // 跳过没有name的route 例如404 {path:*,rediect:'/404'}
    if (!tmp.name) {
      res.push(tmp)
    }
    // 判断是否有权限 或是否跳过权限
    else if (
      hasPermission(userRouteMap, tmp, btnAuthList) ||
      hasSkipPermission(userRouteMap, tmp, btnAuthList)
    ) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(
          tmp.children,
          userRouteMap,
          btnAuthList
        )
        // 根据服务端路由设置路由信息（title，path）
        initRouteInfo(userRouteMap, tmp)
        if (tmp.children.length > 0) {
          // 目录类型跳过权限验证代表只有一个子节点，使用第一个子节点的排序
          if (tmp.meta.skipAuth) {
            tmp.meta.sort = tmp.children[0].meta.sort
          }
          tmp.children = sortRoutes(tmp.children)
          res.push(tmp)
        }
      } else {
        // 根据服务端路由设置路由信息（title，path）
        initRouteInfo(userRouteMap, tmp)
        res.push(tmp)
      }
    }
    // 判断是否有按钮权限对应的路由
    else if (hasBtnPermission(userRouteMap, tmp, btnAuthList)) {
      res.push(tmp)
    }
  })
  return sortRoutes(res)
}

export interface PermissionState {
  routes: any[];
  addRoutes: any[];
  userRouteMap: Record<string, any>;
}

interface PermissionMutaions {
  SET_ROUTES(state: PermissionState, routes: any[]): void;
  RESET_ROUTES(state: PermissionState): void;
}

type AugmentedActionContext = {
  commit<K extends keyof PermissionMutaions>(
    key: K,
    payload?: Parameters<PermissionMutaions[K]>[1]
  ): ReturnType<PermissionMutaions[K]>;
  state: PermissionState;
  rootGetters: any;
};

interface PermissionActions {
  generateRoutes({ commit, rootGetters }: AugmentedActionContext): Promise<any>;
  resetRoutes({ commit }: AugmentedActionContext): void;
}

const getDefaultState = () => {
  return {
    routes: [],
    addRoutes: [],
    userRouteMap: {},
  }
}

const state = getDefaultState()

const mutations: PermissionMutaions = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  },
  RESET_ROUTES: (state) => {
    state.addRoutes = state.routes = []
    state.userRouteMap = {}
  },
}

const actions: PermissionActions = {
  async generateRoutes({ commit, rootGetters }) {
    const [err, res] = await getUserAuthInfo()
    const { userRouterMap, btnAuthList } = genUserRouteMap(res)
    const accessedRoutes = filterAsyncRoutes(
      dynamicRoute,
      userRouterMap,
      btnAuthList as any[]
    )
    const isSuperAdmin = rootGetters['user/isSuperAdmin']
    // 不是超级管理员或者不是平台板的超级管理员可见首页
    if (!isSuperAdmin) {
      accessedRoutes.unshift(mainRoute)
    } else {
      blankRoute.redirect = accessedRoutes[0].children[0].path
      accessedRoutes.unshift(blankRoute)
    }
    commit('SET_ROUTES', accessedRoutes)
    return Promise.resolve(accessedRoutes)
  },
  resetRoutes({ commit }) {
    commit('RESET_ROUTES')
  },
}

const getters = {
  permission_routes: (state: PermissionState) => state.addRoutes,
  all_routes: (state: PermissionState) => state.routes,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
