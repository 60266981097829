import Vue from 'vue'
const TokenKey = 'DAILY_LOG_ACCESS_TOKEN'
const APITokenKey = 'DAILY_LOG_API_ACCESS_TOKEN'
import ls from '@/config/localstorage'

export function getToken() {
  return ls.get(TokenKey, '')
}

export function setToken(token: string) {
  return ls.set(TokenKey, token)
}

export function removeToken() {
  return ls.remove(TokenKey)
}

export function getApiToken() {
  return ls.get(APITokenKey, '')
}

export function setApiToken(token: string) {
  return ls.set(APITokenKey, token)
}

export function removeApiToken() {
  return ls.remove(APITokenKey)
}
