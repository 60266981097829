


























import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class GlobalTitle extends Vue {
  // @Prop({ type: Array, default: [] }) readonly title?: []

  private back(item: any, index: number) {
    // 面包屑第一条不可点击. 特殊情况：备案管理只有一个导航，也可点击
    if (index > 0 || item.path.split('/').length > 2) {
      const config: any = {
        name: item.name,
      }
      if (item.params) {
        config.params = item.params
      }
      this.$router.push(config)
    }
    // this.$emit('back')
  }

  get title() {
    // return this.$route.matched
    const arr = this.$store.state.breadcrumb.arr
    const firstChild = arr[0]
    const secondChild = arr[1]
    if (
      firstChild &&
      secondChild &&
      firstChild.meta.title === secondChild.meta.title
    ) {
      arr.shift()
    }
    return arr
  }
  get showBackIcon() {
    return (
      this.title.length > 2 ||
      (this.title.length > 1 && this.title[0].path.split('/').length > 2) // 特殊情况：备案管理只有一个导航，也可点击
    )
  }
  handleIconClick() {
    this.back(this.title[this.title.length - 2], this.title.length - 2)
  }
}
