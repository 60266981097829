









































import { Component, Vue, Prop, ModelSync, Watch } from 'vue-property-decorator'

@Component
export default class ProxySelect extends Vue {
  @ModelSync('value', 'change', { type: [String, Number, Array, Object] })
  readonly modelValue!: any

  @Prop({ type: Boolean, default: true }) allowClear!: boolean

  @Prop({ type: Function, required: true }) remoteMethod: any

  @Prop({ type: Boolean, default: false }) tree!: boolean

  @Prop({ type: String, default: '请选择' }) placeholder!: string
  private list: any[] = []

  async fetchList() {
    const list = await this.remoteMethod()
    this.list = list
  }
  filterOption(input: any, option: any) {
    return (
      option.componentOptions.children[0].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    )
  }
  handleFilter(inputValue: string, treeNode: any) {
    return treeNode.data.props.title.includes(inputValue)
  }
  created() {
    this.fetchList()
  }
}
