import { notification } from 'ant-design-vue'
import moment from 'moment'
import { RESOURCE } from '@/config/resource.config'
import { REG } from '@/config/validate.config'
export function isIE(): boolean {
  const bw = window.navigator.userAgent
  const compare = (s: any) => bw.indexOf(s) >= 0
  const ie11 = (() => 'ActiveXObject' in window)()
  return compare('MSIE') || ie11 || compare('Edge')
}

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate(id = '', timeout = 1500) {
  if (id === '') {
    return
  }
  setTimeout(() => {
    document.body.removeChild(document.getElementById(id) as any)
  }, timeout)
}

/**
 * 生成网页标签的title
 * @param title 标签名
 */

export const setDocumentTitle = function (title: string) {
  document.title = title
  const ua = navigator.userAgent
  // eslint-disable-next-line
  const regex = /\bMicroMessenger\/([\d\.]+)/;
  if (regex.test(ua) && /ip(hone|od|ad)/i.test(ua)) {
    const i = document.createElement('iframe')
    i.src = '/favicon.ico'
    i.style.display = 'none'
    i.onload = function () {
      setTimeout(function () {
        i.remove()
      }, 9)
    }
    document.body.appendChild(i)
  }
}

/**
 * 过滤对象中为空的属性
 * @param obj
 * @returns {*}
 */
export function filterObj(obj: any) {
  if (!(typeof obj === 'object')) {
    return
  }

  for (const key in obj) {
    if (
      obj.hasOwnProperty(key) &&
      (obj[key] === null || obj[key] === undefined || obj[key] === '')
    ) {
      delete obj[key]
    }
  }
  return obj
}

/**
 * 获取字符串长度，英文字符 长度1，中文字符长度2
 * @param {*} str
 */
export const getStrFullLength = (str = '') =>
  str.split('').reduce((pre, cur) => {
    const charCode = cur.charCodeAt(0)
    if (charCode >= 0 && charCode <= 128) {
      return pre + 1
    }
    return pre + 2
  }, 0)

/**
 * 截取字符串，根据 maxLength 截取后返回
 * @param {*} str
 * @param {*} maxLength
 */
export const cutStrByFullLength = (str = '', maxLength: any) => {
  let showLength = 0
  return str.split('').reduce((pre, cur) => {
    const charCode = cur.charCodeAt(0)
    if (charCode >= 0 && charCode <= 128) {
      showLength += 1
    } else {
      showLength += 2
    }
    if (showLength <= maxLength) {
      return pre + cur
    }
    return pre
  }, '')
}

/**
 *返回大小写字母
 * @returns 返回一个大小写字母的数组
 */
export const getAllLetter = () => {
  const letterStr =
    '1,2,3,4,5,6,7,8,9,0,a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z,A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z'
  return letterStr.split(',')
}
//数字 小数验证
export function validateDecimal(rule: any, value: any, callback: any) {
  if (rule.required && !value) {
    callback(new Error('请填写' + rule.label))
    return false
  }
  if (value && !REG.decimal.test(value)) {
    callback(new Error('格式不正确'))
  } else {
    callback()
  }
}

//数字 小数验证
export function validateDecimal14(rule: any, value: any, callback: any) {
  if (rule.required && !value) {
    callback(new Error('请填写' + rule.label))
    return false
  }
  if (value && !REG.decimal14.test(value)) {
    callback(new Error('格式不正确'))
  } else {
    callback()
  }
}

/**
 *
 * 生成随机数
 * @param {*} min
 * @param {*} max
 * @returns
 */
export const randomNumber = (min: any, max: any) => {
  return Math.floor(Math.random() * (max - min) + min)
}

export /**
 *
 * 生成随机色
 * @param {*} min
 * @param {*} max
 * @returns
 */
const randomColor = (min: any, max: any) => {
  const r = randomNumber(min, max)
  const g = randomNumber(min, max)
  const b = randomNumber(min, max)
  return `rgb(${r}, ${g}, ${b} )`
}

/** 随机生成固定位数或者一定范围内的字符串数字组合
 * @param {Number} min 范围最小值
 * @param {Number} max 范围最大值，当不传递时表示生成指定位数的组合
 * @param {String} charStr指定的字符串中生成组合
 * @returns {String} 返回字符串结果
 * */
export function randomRange(min: number, max: number, charStr: string) {
  let returnStr = '' // 返回的字符串
  let range: any = null // 生成的字符串长度

  // 随机生成字符
  const autoGetStr = function () {
    const charFun = function () {
      const n = Math.floor(Math.random() * 62)
      if (n < 10) {
        return n // 1-10
      } else if (n < 36) {
        return String.fromCharCode(n + 55) // A-Z
      } else {
        return String.fromCharCode(n + 61) // a-z
      }
    }
    while (returnStr.length < range) {
      returnStr += charFun()
    }
  }

  // 根据指定的字符串中生成组合
  const accordCharStrGet = function () {
    for (let i = 0; i < range; i++) {
      const index = Math.round(Math.random() * (charStr.length - 1))
      returnStr += charStr.substring(index, index + 1)
    }
  }
  if (typeof min === 'undefined') {
    min = 10
  }
  if (typeof max === 'string') {
    charStr = max
  }
  range =
    max && typeof max === 'number'
      ? Math.round(Math.random() * (max - min)) + min
      : min

  if (charStr) {
    accordCharStrGet()
  } else {
    autoGetStr()
  }
  return returnStr
}

export const downLoadTemplate = (
  downFn: Function,
  src: string,
  fileName: string
) => {
  const tempFileSrc = src
  try {
    downFn({ url: tempFileSrc }).then((res: any) => {
      if (res && res.status === 0) {
        const { data } = res
        const eleLink = document.createElement('a')
        eleLink.download = fileName
        eleLink.style.display = 'none'
        eleLink.href = data
        eleLink.type =
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        document.body.appendChild(eleLink)
        eleLink.click()
        document.body.removeChild(eleLink)
      }
    })
  } catch (error) {}
}

export const downloadIamge = (
  downFn: Function,
  imgsrc: string,
  name: string
) => {
  //下载图片地址和图片名
  downFn({ url: imgsrc }).then((res: any) => {
    if (res && res.status === 0) {
      const { data } = res
      const image = new Image()
      image.src = data
      // 解决跨域 Canvas 污染问题
      image.setAttribute('crossOrigin', 'anonymous')
      image.onload = function () {
        const canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        const context: any = canvas.getContext('2d')
        context.drawImage(image, 0, 0, image.width, image.height)
        const url = canvas.toDataURL('image/png') //得到图片的base64编码数据
        const a = document.createElement('a') // 生成一个a元素
        const event = new MouseEvent('click') // 创建一个单击事件
        a.download = name || 'photo' // 设置图片名称
        a.href = url // 将生成的URL设置为a.href属性
        a.dispatchEvent(event) // 触发a的单击事件
      }
    }
  })
}

// 下载相关文件
export function downloadFile(url: string, name: string) {
  const suffix = url.substr(url.lastIndexOf('.') + 1)
  const eleLink = document.createElement('a')
  if (suffix === 'pdf') {
    // pdf 在新页面打开
    eleLink.setAttribute('target', '_blank')
  }
  if (name) {
    eleLink.download = name
  }
  eleLink.style.display = 'none'
  eleLink.href = url
  document.body.appendChild(eleLink)
  eleLink.click()
  document.body.removeChild(eleLink)
}

export const downLoadFileFN = async (src: string, fileName: string) => {
  const tempFileSrc = src
  try {
    const responsePromise = await fetch(tempFileSrc)
    const blob = await responsePromise.blob()
    const objectURL = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.setAttribute('download', fileName)
    link.href = objectURL
    document.body.appendChild(link) //添加到页面中，为兼容Firefox浏览器
    link.click()
    document.body.removeChild(link) //从页面移除
  } catch (error) {}
}
// 菜单排序
export function sortMenu(list: any) {
  let arr = []
  if (Array.isArray(list) && list.length > 0) {
    sortKey(list, 'sort')
    list.forEach((item) => {
      if (item.children) {
        item.children = sortKey(item.children, 'sort')
      }
    })
  }
  arr = list
  return arr
}
// 时间戳转stringmoment.unix(date/1000).format(pattern)
export function transDateString(
  timestamp: number,
  pattern = 'YYYY-MM-DD HH:mm:ss'
) {
  return moment.unix(timestamp / 1000).format(pattern)
}

// export const exportBolbData = (downFn: Function, src: string, fileName: string, postData: any) => {
//   downFn(postData).then((res: any) => {
//     if (res) {
//       const blob = new Blob([res], {
//         type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
//       })
//       const fName: any = `${fileName}.xlsx`
//       // @ts-ignor
//       if ((window as any).navigator.msSaveOrOpenBlob) {
//         window.navigator.msSaveBlob(blob, fName)
//       } else {
//         const link = document.createElement('a')
//         const URL = window.URL || window.webkitURL || (window as any).moxURL
//         link.href = URL.createObjectURL(blob)
//         link.download = fName
//         document.body.appendChild(link)
//         link.click()
//         document.body.removeChild(link)
//       }
//     }
//   })
// }

// 菜单排序
export function sortKey(array: any[], key: string | number) {
  return array.sort((a, b) => {
    const x = a[key]
    const y = b[key]
    return x > y ? 1 : x < y ? -1 : 0
  })
}

export const notificationMsg = (msg: any) => {
  notification.error({
    message: '错误',
    description: msg,
  })
}

/**
 * 获取url中的对应参数值
 * @param name
 * @returns {*}
 */
export function getQueryString(url?: string, name?: string) {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  const search = url?.split('?')[1] || ''
  const r = search.match(reg) || []
  return r && r.length ? r[2] : ''
}

export const uuid = () => {
  const s: any[] = []
  const hexDigits = '0123456789abcdef'
  for (let i = 0; i < 32; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23]
  return s.join('')
}

const resizeCallback = new Set()
let resizeFlag = false
export const onWindowResize = (callback: any) => {
  resizeCallback.add(callback)
  if (!resizeFlag) {
    resizeFlag = true
    window.onresize = (e: any) => {
      resizeCallback.forEach((handle: any) => {
        handle(e)
      })
    }
  }
  return () => {
    resizeCallback.delete(callback)
  }
}
