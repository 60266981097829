import type { AxiosRequestConfig } from 'axios'
import type { FetchPlugin } from '../index'

// 添加认证信息
export const network: FetchPlugin = (to, customErrorTips) => {
  return (config: AxiosRequestConfig) => {
    // 断网提示
    if (!window.navigator.onLine) {
      customErrorTips('网络已断开，请检查网络连接！', 'connect_lost')
    }
    return config
  }
}
