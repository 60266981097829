import Vue from 'vue'
import Storage from 'vue-ls'
import { action, down } from '@/plugins/directives/action'

const config = {
  production: process.env.NODE_ENV === 'production',
  storageOptions: {
    namespace: 'pro__',
    name: 'ls',
    storage: 'local',
  },
}

Vue.use(Storage, config, action, down)

const ls = Vue.ls

export default ls
