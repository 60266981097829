import BasicLayout from '@/layout/BasicLayout.vue'

export default {
  path: '/monthPlan',
  name: 'monthPlan',
  component: BasicLayout,
  meta: { title: '' },
  redirect: '/monthPlan/index',
  children: [
    {
      path: '/monthPlan/index',
      component: () => import('@/views/month/index.vue'),
      meta: {
        title: '月计划',
      },
    },
    // {
    //     path: '/week/addWeeklyPlan',
    //     name: 'addWeeklyPlan',
    //     component: () => import('@/views/week/addWeeklyPlan'),
    //     meta: {
    //       title: '周计划',
    //     },
    //   },
  ],
}
