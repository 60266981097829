import type { AxiosRequestConfig } from 'axios'
import qs from 'qs'

//只针对get方式进行序列化
export const getParamSerialize = () => {
  return (config: AxiosRequestConfig) => {
    if (config.method === 'get') {
      // config.paramsSerializer = function (params: any) {
      //   return qs.stringify(params, { arrayFormat: 'repeat' })
      // }
    }
    return config
  }
}
