// 权限开关：测试使用
export const AUTH_SWITCH = true

// 页面按钮权限value映射
export const btnAuthMap = {
  // ==== 台账管理 ====
  // 器具新增
  addMeter: 'meter:manage:add',
  // 器具批量导入
  meterBatchImport: 'meter:manage:batchImport',
  // 器具二维码批量打印
  meterBatchPrint: 'meter:manage:batchPrint',
  // 器具更换机构
  meterChangeOrg: 'meter:manage:changeOrganization',
  // 器具导出选中
  exportMeter: 'meter:manage:exportSelected',
  // 器具导出履历表
  exportMeterResume: 'meter:manage:exportResume',
  // 器具查看
  meterDetail: 'meter:manage:details',
  // 器具编辑
  editMeter: 'meter:manage:edit',
  // 器具删除
  delMeter: 'meter:manage:del',
  // 写入RFID
  nfcPrint: 'meter:manage:nfcPrint',
  // 查看二维码
  previewQrcode: 'meter:manage:qrcodeScan',
  // 证书上传
  scanCertificate: 'meter:manage:scanCertificate',
  // ==== 台账管理 ====

  // ==== 巡检管理 ====
  // 添加巡检计划
  addInspectionPlan: 'inspection:plan:add',
  // 查看巡检计划
  inspectionPlanDetail: 'inspection:plan:details',
  // 巡检计划编辑
  editInspectionPlan: 'inspection:plan:edit',
  // 巡检计划删除
  delInspectionPlan: 'inspection:plan:del',
  // 巡检计划启用停用
  switchInspectionPlan: 'inspection:plan:switch',

  // 新增巡检工单
  addInspectionOrder: 'inspection:order:add',
  // 查看巡检工单
  inspectionOrderDetail: 'inspection:order:details',

  // 巡检记录导出
  exportInspectionRecord: 'inspection:record:export',
  // 巡检记录查看
  inspectionRecordDetail: 'inspection:record:details',
  // ==== 巡检管理 ====

  // ==== 保养管理 ====
  // 添加保养计划
  addMaintainPlan: 'maintain:plan:add',
  // 查看保养计划
  maintainPlanDetail: 'maintain:plan:details',
  // 保养计划编辑
  editMaintainPlan: 'maintain:plan:edit',
  // 保养计划删除
  delMaintainPlan: 'maintain:plan:del',
  // 保养计划启用停用
  switchMaintainPlan: 'maintain:plan:switch',

  // 新增保养工单
  addMaintainOrder: 'maintain:order:add',
  // 查看保养工单
  maintainOrderDetail: 'maintain:order:details',

  // 保养记录导出
  exportMaintainRecord: 'maintain:record:export',
  // 保养记录查看
  maintainRecordDetail: 'maintain:record:details',
  // ==== 保养管理 ====

  // ==== 故障管理 ====
  // 查看故障信息
  faultDetail: 'fault:confirm:details',
  // 确认故障
  confirmFault: 'fault:confirm:confirm',

  // 新增维修工单
  addFaultOrder: 'fault:fixOrder:add',
  // 查看维修工单
  faultOrderDetail: 'fault:fixOrder:details',

  // 维修记录导出
  exportFixRecord: 'fault:fixRecord:export',
  // 维修记录查看
  faultRecordDetail: 'fault:fixRecord:details',
  // 上传溯源文件
  uploadFaultFiles: 'fault:fixRecord:uploadFiles',
  // ==== 故障管理 ====

  // ==== 备案管理 ====
  // 新增备案单
  addRecordOrder: 'record:manage:add',
  // 备案单查看
  recordOrderDetail: 'record:manage:details',
  // 备案单编辑
  editRecordOrder: 'record:manage:edit',
  // 备案单删除
  delRecordOrder: 'record:manage:del',
  // 备案单发送
  sendRecordOrder: 'record:manage:send',
  // ==== 备案管理 ====

  // ==== 计量质控 ====
  // 测量器具新增
  addMeasure: 'measuring:appliance:add',
  // 测量器具批量导入
  measureBatchImport: 'measuring:appliance:batchImport',
  // 测量器具查看
  measureDetail: 'measuring:appliance:details',
  // 测量器具编辑
  editMeasure: 'measuring:appliance:edit',
  // 测量器具删除
  delMeasure: 'measuring:appliance:del',

  // 质控产品新增
  addProduct: 'quality:products:add',
  // 质控产品导出
  exportProduct: 'quality:products:export',
  // 质控产品查看
  productDetail: 'quality:products:details',
  // 质控产品编辑
  editProduct: 'quality:products:edit',
  // 质控产品删除
  delProduct: 'quality:products:del',

  // 测量结果新增
  addResult: 'measuring:result:add',
  // 测量结果导出
  exportResult: 'measuring:result:export',
  // 测量结果查看
  resultDetail: 'measuring:result:details',
  // 测量结果编辑
  editResult: 'measuring:result:edit',
  // 测量结果删除
  delResult: 'measuring:result:del',
  // ==== 计量质控 ====

  // ==== 文件管理 ====

  // 计量标准上传
  addStandard: 'measurement:standard:uploadFiles',
  // 计量标准批量下载
  batchDownloadStandard: 'measurement:standard:batchDownload',
  // 计量标准编辑
  editStandard: 'measurement:standard:edit',
  // 计量标准删除
  delStandard: 'measurement:standard:del',
  // 计量标准下载
  downloadStandard: 'measurement:standard:downloadFile',

  // 技术规范上传
  addTechnology: 'technical:specification:uploadFiles',
  // 技术规范批量下载
  batchDownloadTechnology: 'technical:specification:batchDownload',
  // 技术规范编辑
  editTechnology: 'technical:specification:edit',
  // 技术规范删除
  delTechnology: 'technical:specification:del',
  // 技术规范下载
  downloadTechnology: 'technical:specification:downloadFile',

  // 检定规程上传
  addVerification: 'verification:regulation:uploadFiles',
  // 检定规程批量下载
  batchDownloadVerification: 'verification:regulation:batchDownload',
  // 检定规程编辑
  editVerification: 'verification:regulation:edit',
  // 检定规程删除
  delVerification: 'verification:regulation:del',
  // 检定规程下载
  downloadVerification: 'verification:regulation:downloadFile',
  // ==== 文件管理 ====

  // ==== 系统设置 ====
  //菜单新增
  addMenu: 'system:menu:add',
  //菜单编辑
  editMenu: 'system:menu:edit',
  //菜单删除
  delMenu: 'system:menu:del',

  //权限新增
  addAuth: 'system:auth:add',
  //权限编辑
  editAuth: 'system:auth:edit',
  //权限删除
  delAuth: 'system:auth:del',

  //角色新增
  addRole: 'system:role:add',
  //角色编辑
  editRole: 'system:role:edit',
  //角色删除
  delRole: 'system:role:del',

  //机构新增
  addOrg: 'system:organization:add',
  //机构编辑
  editOrg: 'system:organization:edit',
  //机构删除
  delOrg: 'system:organization:del',

  //用户新增
  addUser: 'system:user:add',
  //用户编辑
  editUser: 'system:user:edit',
  //用户删除
  delUser: 'system:user:del',
  //重置用户密码
  resetUserPwd: 'system:user:restPassword',

  //修改用户密码
  updateUserPwd: 'system:account:updatePassword',
  //重置用户密码
  updateUserInfo: 'system:account:update',
  // ==== 系统设置 ====
}

export const DynamicRouteRefSourceMap: any = {
  BasicLayout: () =>
    import(/* webpackChunkName: "BasicLayout" */ '@/layout/BasicLayout.vue'),
}
export default DynamicRouteRefSourceMap
