import Vue from 'vue'
import './plugins'
import App from './App.vue'
import router from './router'
import store from './store'
import './style/base.less'
import './permission'
import './utils/filter'
// 设置 rem 函数
function setRem() {
  // 320 按照设计稿的宽度来，可以根据实际需要修改
  const baseWidth = 750 
  // 获取当前窗口的宽度
  const clientWidth = document.documentElement.clientWidth || document.body.clientWidth
  // 设置 html 的字体大小
  document.documentElement.style.fontSize = 16 + 'px'
}
 
// 初始化设置 rem
setRem()
 
// 添加窗口变动的监听事件
window.onresize = function() {
  setRem()
}
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
