import ReportLayout from '@/layout/ReportLayout.vue'

export default {
  path: '/report',
  name: 'Report',
  component: ReportLayout,
  meta: { title: '' },
  redirect: '/report/index',
  children: [
    {
      path: '/report/index',
      component: () => import('@/views/report/report/index.vue'),
      meta: {
        title: '汇报',
      },
    },
    {
      path: '/report/stat',
      component: () => import('@/views/report/stat/index.vue'),
      meta: {
        title: '统计',
      },
    },
  ],
}
