























import { Component, Vue, ModelSync, Prop } from 'vue-property-decorator'

@Component
export default class PageModal extends Vue {
  @ModelSync('value', 'input', { type: Boolean, default: false })
  modalVisible!: boolean

  @Prop({ type: String, default: '' }) title!: string
}
