
















































































































































import { Component, Prop, Watch, Vue, ModelSync } from 'vue-property-decorator'
import tupianIcon from '@/assets/year_images/icon_tupian.svg'
import pdfIcon from '@/assets/year_images/icon_wenjianpdf.svg'
import wordIcon from '@/assets/year_images/icon_wenjianword.svg'
import pptIcon from '@/assets/year_images/icon_wenjianppt.svg'
import videoIcon from '@/assets/year_images/icon_video.svg'
import rarIcon from '@/assets/year_images/icon_wenjianrar.svg'

import { uploadFile } from '@/api/common'
import { uuid } from '@/utils/util'
import moment from 'moment'
import { downLoadURlFN } from '@/utils'

@Component({
  components: {},
})
export default class UploadFile extends Vue {
  @ModelSync('value', 'change', { type: [Object, Array], default: () => [] })
  uploadedList!: Array<any>

  @Prop({ type: Boolean, default: false }) disabled!: boolean

  @Prop({
    type: Array,
    default: () => [
      '.docx',
      '.doc',
      '.xls',
      '.xlsx',
      '.jpg',
      '.jpeg',
      '.png',
      '.pdf',
      '.ppt',
      '.pptx',
      '.zip',
      '.rar',
      '.mp4',
      '.avi',
      '.mov',
    ],
  })
  safeType!: Array<string>

  listType = 'text'

  // 文件上传最大数量
  max = 10

  private displayList: any[] = []

  @Watch('uploadedList', { immediate: true })
  handleUploadedListChange() {
    if (!this.uploadedList || !Array.isArray(this.uploadedList)) {
      return
    }
    this.displayList = this.uploadedList.map((item: any) => {
      const result = {
        uid: item.id || item.uid || uuid(),
        file: {
          name: item.attachmentName || item.fileName || item.name,
          attachmentUrl: item.attachmentUrl,
          size: item.attachmentSize,
          createTime: item.createTime,
        },
        url: item.attachmentUrl,
        status: 'done',
        percent: 0,
        rawData: item, // rawData存在代表是回显的文件
      }
      return result
    })
  }

  getFileImg(file: any) {
    const name = file.name ?? ''
    const wordFile = ['.xls', '.xlsx', '.doc', '.docx']
    const pptFile = ['.ppt', '.pptx']
    const rarFile = ['.rar', '.zip']
    const videoFile = ['.mp4', '.avi', '.mov']
    const jpgFile = ['.jpg', '.jpeg', '.png']
    if (
      wordFile.filter((word) => name.toLowerCase().endsWith(word)).length > 0
    ) {
      return wordIcon
    } else if (
      jpgFile.filter((jpg) => name.toLowerCase().endsWith(jpg)).length > 0
    ) {
      return tupianIcon
    } else if (
      pptFile.filter((ppt) => name.toLowerCase().endsWith(ppt)).length > 0
    ) {
      return pptIcon
    } else if (
      rarFile.filter((rar) => name.toLowerCase().endsWith(rar)).length > 0
    ) {
      return rarIcon
    } else if (
      videoFile.filter((video) => name.toLowerCase().endsWith(video)).length > 0
    ) {
      return videoIcon
    } else {
      return pdfIcon
    }
  }

  getFileSize(file: any) {
    const size = Math.max(0.01, file.size / 1024 / 1024)
    return size.toFixed(2) + 'M'
  }

  getFileSuffix(file: any) {
    const { fileName, name } = file
    const _name = fileName || name
    return _name.substring(_name.lastIndexOf('.') + 1, _name.length)
  }

  handleChange({ file, fileList }: { file: any; fileList: Array<any> }) {
    if (file.status === 'done' || file.status === 'removed') {
      this.displayList = fileList
      const result = fileList
        .filter((fileItem: any) => fileItem.status !== 'uploading')
        .map((fileItem: any) => {
          const fileObj = fileItem.response || fileItem.rawData
          return {
            uid: fileItem.uid,
            attachmentUrl: fileObj.attachmentUrl,
            attachmentName: fileObj.attachmentName,
            attachmentSize: fileObj.attachmentSize,
            createTime: fileObj.createTime,
          }
        })
      this.$emit('change', result)
    }
  }

  beforeUpload(file: any) {
    const fileName = file.name.toLowerCase()
    const isSafeType = this.safeType.some((type) => fileName.endsWith(type))
    if (!isSafeType) {
      this.$message.warn(`上传文件格式只能是${this.safeType.toString()}！`)
      return false
    }
    const isLt15M = file.size / 1024 / 1024 < 50
    if (!isLt15M) {
      this.$message.warn('上传文件大小不能超过 50MB!')
      return false
    }
    return isSafeType && isLt15M
  }

  toBase64File(file: any): Promise<string> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result as string)
      }
    })
  }

  // 自定义上传
  private uploadCount: number = 0
  async customRequest(req: any) {
    if (this.displayList.length < this.max) {
      // 上传必须是异步
      const uid = uuid()
      this.displayList.push({
        uid,
        file: req.file,
        status: 'uploading',
        percent: 0,
        response: {},
      })
      const fileItem = this.displayList.find((item) => item.uid === uid)
      const base64File = await this.toBase64File(req.file)
      const params = {
        // 0公有，1私有
        // type: 0,
        type: 1,
        fileName: req.file.name,
        suffix: this.getFileSuffix(req.file),
        bytes: base64File.split(',')[1],
      }
      this.uploadCount++
      const [err, res] = await uploadFile(params, {
        onUploadProgress: (ev: any) => {
          const percent = (ev.loaded / ev.total) * 100
          this.$set(fileItem, 'percent', percent)
        },
      })
      this.uploadCount--
      if (err) {
        this.displayList = this.displayList.filter((item) => item.uid !== uid)
        return
      }
      this.$set(fileItem, 'status', 'done')
      this.$set(fileItem, 'response', {
        attachmentUrl: res.url,
        attachmentName: req.file.name,
        attachmentSize: req.file.size,
        createTime: moment(res.createTime).format('YYYY-MM-DD HH:mm:ss'),
      })
      if (this.uploadCount === 0) {
        this.handleChange({
          file: fileItem,
          fileList: this.displayList,
        })
      }
    } else {
      this.$message.destroy()
      this.$message.warn(`最多上传${this.max}个文件`)
    }
  }

  delFile(index: number) {
    const fileItem = this.displayList[index]
    this.displayList.splice(index, 1)
    fileItem.status = 'removed'
    if (this.uploadCount === 0) {
      this.handleChange({
        file: fileItem,
        fileList: this.displayList,
      })
    }
  }

  downloading: boolean = false
  currentFileItem: any = {}
  download(file: any) {
    this.currentFileItem = file
    this.downloading = true
    setTimeout(async () => {
      try {
        const downloadUrl = file.rawData.showUrl
        await downLoadURlFN(downloadUrl, file.rawData.attachmentName)
      } catch (error) {
        console.error('downLoadURlFN', error)
      } finally {
        this.downloading = false
      }
    }, 300)
  }

  previewVisible: boolean = false
  previewUrl: string = ''
  preview(file: any) {
    // this.currentFileItem = file
    // const fileUrl = file.rawData.showUrl
    //  const url = process.env.VUE_APP_PREVIEW_FILE_URL + Base64.encode(fileUrl)
    //  this.previewUrl = url
    //  this.previewVisible = true
    this.currentFileItem = file
    const [rawFileUrl, rawFileToken] = file.rawData.showUrl.split('?')
    const fileUrl = decodeURIComponent(rawFileUrl)
    // // const fileUrl = rawFileUrl.replaceAll('%2B', '%252B')
    // // const fileUrl = rawFileUrl.replaceAll('%2B', '%20')
    // const fileUrl = rawFileUrl.replaceAll('%2B', '+')
    console.log('preivew url', fileUrl, rawFileToken)
    const url =
      process.env.VUE_APP_PREVIEW_FILE_URL +
      encodeURIComponent(Base64.encode(fileUrl + '?' + rawFileToken))


    this.previewUrl = url
    this.previewVisible = true
  }
  cancelPreview() {
    this.previewUrl = ''
    this.previewVisible = false
    this.fullscreen = false
  }
  fullscreen: boolean = false
  toogleFullscreen() {
    this.fullscreen = !this.fullscreen
  }
}
