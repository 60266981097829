import Vue from 'vue'
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')

Vue.filter('dayjs', function (dataStr: string, pattern = 'YYYY-MM-DD') {
  if (!dataStr) {
    return ''
  }
  return moment(dataStr).format(pattern)
})

Vue.filter('moment', function (dataStr: string, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr).format(pattern)
})
Vue.filter('momentStr', function (date: number, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment.unix(date / 1000).format(pattern)
})
Vue.filter('diff', function (dataStr: string, pattern = 'YYYY-MM-DD HH:mm:ss') {
  const oldTime = moment(dataStr)
  const newTime = moment(new Date())
  return newTime.diff(oldTime, 'minutes') <= 0 ? '1分钟以前' : newTime.diff(oldTime, 'minutes') < 60 ? `${newTime.diff(oldTime, 'minutes')}分钟前` : newTime.diff(oldTime, 'minutes') <= 1440 ? `${newTime.diff(oldTime, 'hours')}小时前` : `${newTime.diff(oldTime, 'days')}天前`
})


export function dayjs(dataStr:string, pattern:string = 'YYYY-MM-DD') {
  if (!dataStr) {
    return ''
  }
  return moment(dataStr).format(pattern)
}