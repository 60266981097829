import { GET, POST, PUT } from '@/config/fetch'

// 日统计数据
export const getDayStatisticsData = () =>
  GET(
    '/front/dailylog/v1/dailyPlan/dailyStatistics',
  )

  // 周统计数据
export const getWeekStatisticsData = () =>
GET(
  `/front/dailylog/v1/weekPlan/weekStatistics`,
)

// 周统计数据
export const userPlanList = (userId: string, planType: number, params: any) =>
  GET(
    `/front/dailylog/v1/report/pageListByUser/${userId}/${planType}`,
    params,
  )
// 统计列表-计划分页列表查询
export const getPlanList = (params: any) =>
  GET(
    `/front/dailylog/v1/dailyPlan/planList`,
    params,
  )

 // 设置岗位按钮隐藏
  export const setTingBtnShow = () =>
  GET(
    `/front/dailylog/v1/positionPermission/currentUserPermission`,
  )

  // 设置统计隐藏按钮
  export const setTingMenu = () =>
  GET(
    `/front/dailylog/v1/positionPermission/currentUserManageScope`,
  )

 // 查询所有岗位列表
 export const getPositionPermissionList = () =>
 GET(
   `/front/dailylog/v1/positionPermission/selectPosition`,
 )

 
 // 查询已选岗位列表
 export const getPositionPermissionListChosed = () =>
 GET(
   `/front/dailylog/v1/positionPermission/list`,
 )
 // 统计-有权限的用户组织树
 export const getHasAuthUserTree = () =>
 GET(
   `/front/dailylog/v1/org/orgUser/orgAndUserTree`,
 )

  // 设置岗位
  export const setpsiotion = (params: any) =>
  POST(
    `/front/dailylog/v1/positionPermission/position/batch`,
    params
  )

// 导出汇统计计划分页
export const exportStatisList = (
  params:any,
  fileName:any
) =>
  GET(
    `/front/dailylog/v1/dailyPlan/export`,
    { ...params, fileName},
    {
      responseType: 'blob',
    },
  )

  
