<template>
  <div class="member-input-wrapper" @click.stop="openMoadlVisible">
    <a-input
      class="member-input-inner"
      readOnly
      :value="modelValue.map((item) => item.receiverName).join()"
      placeholder="请选择人员"
    >
      <a-icon slot="suffix" type="user" />
    </a-input>
    <PersonMultile
      v-if="isMoadlVisible"
      :personVisible="isMoadlVisible"
      :personList="treeData"
      :personCheckList="avatarList"
      @personCancel="closeMoadlVisible"
      @personCheckUpdate="addPositionMultil"
    />
  </div>
</template>

<script>
import PersonMultile from '../personMultile/index.vue'
import { getHasAuthUserTree } from '@/api/statistics'

export default {
  components: {
    PersonMultile,
  },
  props: {
    value: {
      type: [String, Number, Array, Object],
      required: true,
    },
  },
  data() {
    return {
      isMoadlVisible: false,
      treeData: [],
      avatarList: [],
    }
  },
  computed: {
    modelValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    selectValue() {
      return this.modelValue.map((item) => item.receiverId)
    },
  },
  methods: {
    async getSysOrgByToken() {
      const [err, data] = await getHasAuthUserTree(0)
      if (!err) {
        this.treeData = data
      }
    },
    addPositionMultil(e) {
      this.avatarList = e
      this.modelValue = this.avatarList.map((item) => {
        return {
          receiverId: item.id,
          headSculpture: item.headSculpture,
          planType: this.planType,
          receiverName: item.dataRef.title,
        }
      })
    },
    openMoadlVisible() {
      this.isMoadlVisible = true
    },
    closeMoadlVisible() {
      this.isMoadlVisible = false
    },
  },
  mounted() {
    this.getSysOrgByToken()
  },
}
</script>

<style lang="less" scoped>
.member-input-wrapper {
  /deep/ .ant-select-selection--multiple .ant-select-selection__choice {
    padding-right: 10px;
  }
  /deep/.ant-select-selection__choice__remove {
    display: none;
  }
}
.member-input-inner {
  width: 300px;
  /deep/ &.ant-input-affix-wrapper .ant-input:not(:last-child) {
    // width: 270px;
    height: 34px;
    border: 1px solid #ccc;
    padding: 6px 25px;
    padding-left: 11px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    pointer-events: none;
  }
}
</style>
