<script>
export default {
  props: {
    isPreview: { type: Boolean, default: false },
    displayText: { type: [String, Number], default: '' },
  },
  render() {
    const vnode = this.$slots.default
    // 修复slot不触发表单验证问题
    if (vnode.length > 0) {
      const { blur, change } = this.$listeners
      const listeners = vnode[0].componentOptions.listeners
      const { blur: vBlur, change: vChange } = listeners
      if (!('hasBlur' in listeners)) {
        Object.defineProperties(listeners, {
          hasBlur: {
            writable: false,
            value: Boolean(vBlur),
          },
        })
        if (listeners.hasBlur) {
          listeners.originalBlur = vBlur
        }
      }
      if (!('hasChange' in listeners)) {
        Object.defineProperties(listeners, {
          hasChange: {
            writable: false,
            value: Boolean(vChange),
          },
        })
        if (listeners.hasChange) {
          listeners.originalChange = vChange
        }
      }

      listeners.blur = function (...args) {
        if (listeners.hasBlur) {
          listeners.originalBlur(...args)
        }
        blur(...args)
      }

      listeners.change = function (...args) {
        if (listeners.hasChange) {
          listeners.originalChange(...args)
        }
        change(...args)
      }
    }
    return this.isPreview ? (
      <p class="field-content">{this.displayText}</p>
    ) : (
      vnode
    )
  },
}
</script>
