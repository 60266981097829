


















































import { Component, Vue, Prop } from 'vue-property-decorator'

import { filterObj } from '@/utils/util'

import { snakeCase } from 'lodash-es'
const deleteAction = (a: any, b?: any) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error('deleteAction 未实现')
  }
  return [{}, null]
}

@Component
export default class TableComponent extends Vue {
  private loading: boolean = false
  private currentPage: number = 1
  private pageSize: number = 10
  private total: number = 0

  private tableData: any[] = []
  @Prop({
    type: Boolean,
    default: false,
  })
  readonly lazeInit!: boolean // 是否懒加载数据
  @Prop({
    type: String,
    default: 'middle',
  })
  readonly size!: string // 大小
  @Prop({
    type: Boolean,
    default: true,
  })
  readonly bordered!: boolean // 边线
  @Prop({
    type: String,
    default: 'id',
  })
  readonly rowKey!: string // 表格行 key
  @Prop({
    type: Object,
    default: undefined,
  })
  readonly scroll!: any // 表格行 key
  @Prop({
    type: Boolean,
    default: true,
  })
  readonly isPage!: boolean // 是否分页
  @Prop({
    type: Boolean,
    default: false,
  })
  readonly showSize!: boolean // 是否显示页面条数
  @Prop({
    type: Boolean,
    default: true,
  })
  readonly showQuickJumperFlag!: Boolean // 是否显示跳转
  @Prop({
    type: Boolean,
    default: false,
  })
  readonly isSelect!: boolean // 是否带复选框

  @Prop({
    type: String,
    default: 'checkbox',
  })
  readonly selectType!: string // 是否带复选框

  @Prop({
    type: Object,
    required: false,
  })
  readonly rowSelection!: string // 复选配置

  @Prop({
    type: Function,
  })
  readonly apiFun: Function | undefined //api  (若果传了apiFun，默认请求)

  @Prop({
    type: Function,
  })
  readonly setParams: Function | undefined //接口参数

  @Prop({
    type: Function,
  })
  readonly pageChange: Function | undefined // 页码改变的回调函数

  @Prop({
    type: Array,
    default: true,
  })
  readonly columns!: Array<any> | [] // 表头
  @Prop({
    type: Array,
    default: () => [],
  })
  selectedRowKeys!: Array<any> | []

  @Prop({
    type: String,
    default: '',
  })
  readonly deleteBatch?: string | undefined // 删除url

  @Prop({
    type: Array,
    default: () => [],
  })
  readonly ourterTableData!: Array<any> | []
  @Prop({
    type: Boolean,
    default: false,
  })
  readonly ourterLoading!: boolean

  created() {
    this.pageOption.showQuickJumperFlag = this.showQuickJumperFlag
    this.pageOption.showSizeChanger = this.showSize
  }

  private pageOption: any = {
    showTotal: (total: any) => `共 ${total} 条`,
    total: 0,
    pageSize: 10,
  }

  // 复选框配置
  private rowSelectionOption: any = {}

  beforeMount() {
    this.rowSelectionOption = this.rowSelection
      ? this.rowSelection
      : {
          selectedRowKeys: this.selectedRowKeys,
          onChange: (selectedRowKeys: any, selectedRows: any) => {
            this.rowSelectionOption.selectedRowKeys = selectedRowKeys
            this.$emit('rowSelectionChange', selectedRows)
          },
          onSelect: (...args: any[]) => {
            this.$emit('rowSelectionSelect', ...args)
          },
          onSelectAll: (...args: any[]) => {
            this.$emit('rowSelectionSelectAll', ...args)
          },
          type: this.selectType,
        }
  }

  // api获取数据
  private mounted() {
    if (!this.lazeInit) {
      this.getData()
    }
  }
  private handleTableChange(pagination: any, filters: any, sorter: any) {
    if (Object.keys(sorter).length > 0) {
      this.$emit('sorterChange', {
        order: sorter.order,
        field: snakeCase(sorter.field),
      })
    }
    this.currentPage = pagination.current
    this.pageOption.current = pagination.current
    this.pageOption.pageSize = pagination.pageSize
    this.pageSize = pagination.pageSize
    this.getData(false)
  }
  private shadowQuery: any = {}
  private async getData(resetParam?: boolean) {
    if (this.apiFun) {
      if (this.loading) return
      this.loading = true
      const pagination = {
        current: this.currentPage,
        size: this.pageSize,
      }
      let query: any = {}
     
      const queryParam = this.setParams ? this.setParams(pagination) : {}
      //  console.log(queryParam)
      if (resetParam || Object.keys(this.shadowQuery).length === 0) {
        query = this.shadowQuery = {
          ...queryParam,
        }
      } else {
        query = {
          ...this.shadowQuery,
        }
        query.desc = queryParam.desc
        query.asc = queryParam.asc
      }
      if (this.isPage) {
        if (query['current'] === undefined) {
          query = {
            ...pagination,
            ...query,
          }
        }
      }
      // 请求数据时，清空当前选中
      if (this.rowSelectionOption.onChange) {
        this.rowSelectionOption.onChange([], [])
      }
      const [err, res] = await this.apiFun(filterObj(query))
      this.loading = false
      if (!err) {
        if (this.isPage) {
          this.tableData = res.records
          const pageOption = {
            ...this.pageOption,
          }
          pageOption.total = res.total
          this.pageOption = pageOption
        } else {
          this.tableData = res
        }
      }
    }
  }
  //刷新数据(使用ref外部调用)
  // val true为点击查询或重置按钮
  private refresh(val: boolean = false) {
    if (this.apiFun) {
      if (val) {
        this.currentPage = 1
        this.pageOption.current = 1
        this.getData(val)
      } else {
        this.getData(val)
      }
    }
  }

  private deleteRecrod(id: string, message: string = '是否删除选中数据') {
    this.$confirm({
      title: '确认删除',
      content: message + '?',
      onOk: async () => {
        const [err] = await deleteAction(this.deleteBatch, {
          id: id,
        })
        if (!err) {
          this.$message.success('删除成功!')
          this.currentPage = 1
          this.pageOption.current = 1
          this.getData(false)
        }
      },
    })
  }
  resetPage() {
    this.currentPage = 1
    this.pageOption.current = 1
  }
}
