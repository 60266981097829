import type { AxiosResponse } from 'axios'
import type { FulfilledInterceptor, FetchPlugin } from '../index'

import { redirectLogin } from './auth'

import { errorLog } from '@/utils/log'
import moment from 'moment'

// 导出表格数据
export const exportExcel: FetchPlugin = (to, customErrorTips) => {
  // 把导出时的blob数据转换为json
  function errorBlobData(res: AxiosResponse) {
    const { data } = res
    const reader = new FileReader()
    reader.readAsText(data, 'utf-8')
    reader.onload = function () {
      try {
        const _data = JSON.parse(reader.result as string)
        res.data = _data
        if (
          !(redirectLogin(to, customErrorTips) as FulfilledInterceptor)(res)
        ) {
          customErrorTips(_data.msg || _data.message)
        }
      } catch (e) {
        customErrorTips('导出错误')
      }
    }
    return Promise.reject(new Error('导出失败'))
  }
  return [
    (res: AxiosResponse) => {
      if (Array.isArray(res)) {
        return res
      }
      const {
        data,
        headers,
        config: { params },
      } = res
      if (data.type === 'application/json') {
        return to(errorBlobData(res))
      }
      console.log(res)
      let filename:string = ''
      if(params) {
        filename = params.fileName + '_' + moment().format('YYYY-MM-DD-HHmmss') + '.xlsx'
      } else {
        filename = JSON.parse(res.config.data).fileName + '_' + moment().format('YYYY-MM-DD-HHmmss') + '.xlsx'
      }
     
      // const head =
      //   headers['Content-Disposition'] ||
      //   headers['Content-disposition'] ||
      //   headers['content-Disposition'] ||
      //   headers['content-disposition']
      // if (head) {
      //   // filename 根据实际情况转译
      //   filename = decodeURIComponent(
      //     head.slice(head.indexOf('filename=') + 'filename='.length)
      //   )
      // }
      // @ts-ignore
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // @ts-ignore
        window.navigator.msSaveOrOpenBlob(data, filename)
      } else {
        const blob = data
        const linkNode = document.createElement('a')
        linkNode.download = filename // a标签的download属性规定下载文件的名称
        linkNode.style.display = 'none'
        linkNode.href = URL.createObjectURL(blob) // 生成一个Blob URL
        document.body.appendChild(linkNode)
        linkNode.click() // 模拟在按钮上的一次鼠标单击
        URL.revokeObjectURL(linkNode.href) // 释放URL 对象
        document.body.removeChild(linkNode)
      }
      return to(Promise.resolve())
    },
    (error) => {
      errorLog(`request error `, error)
      // customErrorTips(`表格数据错误`)
      return to(Promise.reject(error))
    },
  ]
}
